<template>
  <div>

    <TblUser />
  </div>
</template>

<script>
import usersData from "./UsersData";
import TblUser from "../components/TblUser";
import ChartUser from "../components/ChartUser";

export default {
  name: "Users",
  components: { TblUser, ChartUser },
  data() {
    return {
      userData: this.$cookies.get("user"),
    };
  },
  watch: {},
  methods: {
    getBadge(status) {
      switch (status) {
        case "Active":
          return "success";
        case "Inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
    rowClicked(item, index) {
      this.$router.push({ path: `users/${index + 1}` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
