<template>
  <CCard style="margin-top: 20px">
    <CCardHeader class="bg-skw-blue text-white"><strong>บุคลากรโรงเรียน{{ userData.school_name }}</strong>
    </CCardHeader>
    <CCardBody v-if="$store.state.teacherList">
      <CRow>
        <CCol lg="12">
          <strong> จำนวนบุคลากรโรงเรียน{{ userData.school_name }}จำแนกตามตำแหน่ง </strong>
          <CChartBar style="height:300px" :datasets="[
            {
              data: [chartData.teacher,
              chartData.asTeacher,
              chartData.vice,
              chartData.director,
              chartData.nd],
              backgroundColor: [$store.state.bgColor[0], $store.state.bgColor[1], $store.state.bgColor[2], $store.state.bgColor[3]],
              label: [' คน'],
            }
          ]" :labels=positionLabel :options="{ maintainAspectRatio: false }" />
        </CCol>
        <CCol lg="12" style="margin-top:20px">
          <strong> จำนวนบุคลากรโรงเรียน{{ userData.school_name }}จำแนกตามกลุ่มสาระฯ/ ฝ่าย </strong>


          <CChartBar style="height:300px" :datasets="[
            {
              data: [chartData.dep_AR,
              chartData.dep_FL,
              chartData.dep_MA,
              chartData.dep_OC,
              chartData.dep_PH,
              chartData.dep_SO,
              chartData.dep_ST,
              chartData.dep_TH,
              chartData.dep_acad,
              chartData.dep_admin,
              chartData.dep_affaire,
              chartData.dep_general,
              chartData.dep_nd,],
              backgroundColor: [$store.state.bgColor[0], $store.state.bgColor[1], $store.state.bgColor[2], $store.state.bgColor[3], $store.state.bgColor[4], $store.state.bgColor[5], $store.state.bgColor[6], $store.state.bgColor[7], $store.state.bgColor[8], $store.state.bgColor[9], $store.state.bgColor[10], $store.state.bgColor[11], $store.state.bgColor[12]],
              label: [' คน'],
            }
          ]" :labels=departmentLabel :options="{ maintainAspectRatio: false }" />

        </CCol>
        <CCol lg="12" style="margin-top:20px">
          <strong> จำนวนบุคลากรโรงเรียน{{ userData.school_name }} จำแนกตามวิทยฐานะ </strong>


          <CChartBar style="height:300px" :datasets="[
            {
              data: [chartData.as_masterTeacher,
              chartData.as_spacialMasterTeacher,
              chartData.as_expertTeacher,
              chartData.as_masterVice,
              chartData.as_specialMasterVice,
              chartData.as_expertVice,
              chartData.as_masterDirector,
              chartData.as_specialMasterDirector,
              chartData.as_expertDirector,
              chartData.as_nd,],
              backgroundColor: [$store.state.bgColor[0], $store.state.bgColor[1], $store.state.bgColor[2], $store.state.bgColor[3], $store.state.bgColor[4], $store.state.bgColor[5], $store.state.bgColor[6], $store.state.bgColor[7], $store.state.bgColor[8], $store.state.bgColor[9]],
              label: [' คน'],
            }
          ]" :labels="acadStand" :options="{ maintainAspectRatio: false }" />

        </CCol>
        <CCol lg="12" style="margin-top:20px">
          <strong>จำนวนบุคลากรโรงเรียน{{ userData.school_name }} จำแนกตามวุฒิการศึกษา </strong>
          <CChartBar style="height:300px" :datasets="[
            {
              data: [chartData.deg_bac,
              chartData.deg_mas,
              chartData.deg_doc,
              chartData.deg_nd,
              ],
              backgroundColor: [$store.state.bgColor[0], $store.state.bgColor[1], $store.state.bgColor[2], $store.state.bgColor[3]],
              label: [' คน'],
            }
          ]" :labels="degreeLabel" :options="{ maintainAspectRatio: false }" />

        </CCol>



      </CRow>

      <vue-good-table :columns="columns" :rows="$store.state.teacherList" :line-numbers="true"
        :search-options="{ enabled: true }" :sort-options="{
            enabled: true,
            initialSortBy: { field: 'user_fname', type: 'asc' },
          }" :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }" style="margin-top:50px">
        >
        <div slot="table-actions">
          <button class="btn btn-primary" style="margin-right: 10px" @click="modalCreate = true">
            +add
          </button>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <CDropdown>
              <template #toggler>
                <CHeaderNavLink>
                  <button class="btn btn-secondary btn-block">Tools</button>
                </CHeaderNavLink>
              </template>

              <CDropdownItem v-if="userData.user_level.search('adminHR') >= 0">
                <div class="text-center" style="width: 100%" @click="openModalEdit(props.row.originalIndex)">
                  edit
                </div>
              </CDropdownItem>
            </CDropdown>
          </span>

          <span v-else-if="props.column.field == 'pictures'">
            <img v-for="pic in JSON.parse(props.row.teaching_pictures)" :key="pic.id" :src="pic" width="100%" />
          </span>
          <span v-else-if="props.column.field == 'teaching_who_comment'">
            <span class="badge badge-secondary" v-if="props.row.teaching_who_comment == 0">รอการนิเทศ</span>
            <span class="badge badge-success" v-else>นิเทศโดย {{ props.row.teaching_who_comment }}</span>
          </span>
        </template>
      </vue-good-table>
      <CRow style="margin-top: 20px">
        <CCol>
          <div style="text-align: right">
            <!-- เพิ่มปุ่ม Export
            <button @click="modalPrint = true" class="btn btn-secondary">
              Print โดยกรองจากวันที่
            </button>
            -->
          </div>
        </CCol>
      </CRow>
      <CRow style="margin-top: 20px">
        <CCol>
          <div style="text-align: right">
            <button @click="onExport" class="btn btn-secondary">Export</button>
            <!-- เพิ่มปุ่ม Export -->
          </div>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardBody v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only"></span>
      </div>
      <div>Loading...</div>
    </CCardBody>
    <!-------------------MODAL CREATE--------------------------------------------------------------------------------------->
    <form id="formCreateUser" v-on:submit.prevent="createUser" method="POST">
      <CModal :show.sync="modalCreate" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="dark">
        <CRow><!--body-->

          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสผู้รายงาน
              </label>
              <input type="text" name="txtUserId" class="form-control" readonly :value="userData.user_id" />
            </div>
          </CCol>
          <CCol lg="6"></CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                เลขประจำตัวประชาชน:
              </label>
              <input type="text" name="CID" class="form-control" required />
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                Password:
              </label>
              <input type="text" name="pw" class="form-control" required />
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ชื่อ:
              </label>
              <input type="text" name="fName" class="form-control" required />
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                นามสกุล:
              </label>
              <input type="text" name="lName" class="form-control" required />
            </div>
          </CCol>


        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">
              เพิ่มบุคลากร:
            </h3>
          </h6>
          <CButtonClose @click="modalCreate = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalCreate = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL EDIT--------------------------------------------------------------------------------------->
    <form id="formUpdateLog" v-on:submit.prevent="updateUser" method="POST">
      <CModal :show.sync="modalEdit" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="danger" v-if="selectedUser">
        <CRow><!--body-->
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสผู้รายงาน:
              </label>
              <input type="text" name="txtUserId" class="form-control" readonly :value="userData.user_id" />
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสครู:
              </label>
              <input type="text" name="userId" class="form-control" readonly :value="selectedUser.user_id" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ชื่อ:
              </label>
              <input type="text" name="fName" class="form-control" :value="selectedUser.user_fname" required />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                นามสกุล:
              </label>
              <input type="text" name="lName" class="form-control" :value="selectedUser.user_lname" required />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                <strong>รูปถ่าย:</strong>
              </label>
              <div>
                <img :src="$hostUrl + selectedUser.user_pic" width="80%" />
              </div>
            </div>
          </CCol>

          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                <strong>อัพโหลดรูปภาพ:</strong>
              </label>

              <UploadImages @changed="handleImages" :max="1" maxError="อัพโหลดได้เพียง 1 ภาพ เท่านั้น"
                uploadMsg="คลิ๊กเพื่อเลือกภาพ" />
            </div>
          </CCol>

          <CCol lg="6" v-if="userData">
            <SltPosition />
          </CCol>
          <CCol lg="6" v-if="userData">
            <SltAcademicStanding />
          </CCol>
          <CCol lg="12" v-if="userData">
            <SltDepartment />
          </CCol>
          <CCol lg="6" v-if="userData">
            <SltDegree />
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                วิชาเอก:
              </label>
              <input type="text" name="userMajor" class="form-control" :value="selectedUser.user_major" required />
            </div>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไขข้อมูลบุคคลากร:</h3>
          </h6>
          <CButtonClose @click="modalEdit = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalEdit = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL SEARCH FOR PRINT--------------------------------------------------------------------------------------->
    <form id="formSearchForPrint" v-on:submit.prevent="searchForPrint" method="POST">
      <CModal :show.sync="modalPrint" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="warning">
        <CRow><!--body-->

          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสโรงเรียน
              </label>
              <input type="number" name="schoolCode" class="form-control" readonly v-model="userData.user_address" />
            </div>
          </CCol>
          <CCol lg="12" class="text-right">
            <button type="submit" class="btn btn-yellow">Search</button>
          </CCol>
          <CCol lg="12" v-if="teachingLogInfo">
            <table v-for="teachingLog in teachingLogInfo" :key="teachingLog.id" style="margin-top: 20px">
              <tbody>
                <tr>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                  <td width="10%"></td>
                </tr>
                <tr>
                  <td colspan="10">
                    <div class="text-center bg-warning" style="padding: 10px">
                      <h3>รายงานการจัดกิจกรรมการเรียนการสอน</h3>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    ชื่อครูผู้สอน: {{ teachingLog.user_fname }}
                    {{ teachingLog.user_lname }}
                  </td>
                  <td colspan="4">
                    รายวิชา: {{ teachingLog.subject_code }}
                    {{ teachingLog.subject_name }}
                  </td>
                  <td colspan="3">
                    ชั้น: {{ teachingLog.level_abv_name }} ห้อง (กลุ่ม):
                    {{ teachingLog.teaching_std_class }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    วันที่สอน: {{ teachingLog.teaching_date }}
                  </td>
                  <td colspan="4"></td>
                  <td colspan="3">คาบ: {{ teachingLog.teaching_periods }}</td>
                </tr>
                <tr>
                  <td colspan="3">
                    จำนวนนักเรียนทั้งหมด: {{ teachingLog.teaching_std_amount }}
                  </td>
                  <td colspan="4">
                    จำนวนนักเรียนที่เข้าเรียน:
                    {{ teachingLog.teaching_std_attended }}
                  </td>
                  <td colspan="3">
                    ร้อยละ:
                    {{
                      (
                        (Number(teachingLog.teaching_std_attended) /
                          Number(teachingLog.teaching_std_amount)) *
                        100
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    ช่องทางการสอน: {{ teachingLog.route_name }}
                  </td>
                  <td colspan="4">
                    ส่งรายงาน: {{ teachingLog.teaching_created }}
                  </td>
                  <td colspan="3">
                    แก้ไขครั้งสุดท้าย: {{ teachingLog.teaching_updated }}
                  </td>
                </tr>
                <tr>
                  <td colspan="10">
                    รายชื่อนักเรียนที่ขาดเรียน:
                    <div>
                      <span v-for="missingStd in JSON.parse(
                        teachingLog.teaching_std_missing
                      )" :key="missingStd.id">{{ missingStd.label }},
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="10">
                    ผลการจัดการเรียนการสอน ปัญหา และข้อเสนอแนะ:
                    <div v-if="teachingLog.teaching_problem">
                      {{ teachingLog.teaching_problem }}
                    </div>
                    <div v-else class="alert alert-warning">ไม่มี</div>
                  </td>
                </tr>
                <tr>
                  <td colspan="10">
                    ภาพประกอบ:
                    <img width="100%" :src="JSON.parse(teachingLog.teaching_pictures)[0]" v-if="JSON.parse(teachingLog.teaching_pictures)[0].search(
                      'http'
                    ) >= 0
                      " />
                    <img :src="JSON.parse(teachingLog.teaching_pictures)[1]" v-if="JSON.parse(teachingLog.teaching_pictures)[1].search(
                        'http'
                      ) >= 0
                      " />
                    <img :src="JSON.parse(teachingLog.teaching_pictures)[2]" v-if="JSON.parse(teachingLog.teaching_pictures)[2].search(
                        'http'
                      ) >= 0
                      " />
                    <img :src="JSON.parse(teachingLog.teaching_pictures)[3]" v-if="JSON.parse(teachingLog.teaching_pictures)[3].search(
                        'http'
                      ) >= 0
                      " />
                  </td>
                </tr>
                <tr>
                  <td colspan="10">
                    ผลการนิเทศ:
                    <div v-if="teachingLog.teaching_comment">
                      {{ teachingLog.teaching_comment }}
                      <div>
                        นิเทศโดย: {{ teachingLog.commentator_fname }}
                        {{ teachingLog.commentator_lname }}
                      </div>
                    </div>
                    <div v-else class="alert alert-warning">รอการนิเทศ</div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">............................</td>
                  <td colspan="4"></td>
                  <td colspan="3">............................</td>
                </tr>
                <tr>
                  <td colspan="3">(............................)</td>
                  <td colspan="4"></td>
                  <td colspan="3">(............................)</td>
                </tr>
                <tr>
                  <td colspan="3">รองผู้อำนวยการฝ่ายบริหารวิชาการ</td>
                  <td colspan="4"></td>
                  <td colspan="3">ผู้อำนวยการ</td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">ปริ้นบันทึกการสอน:</h3>
          </h6>
          <CButtonClose @click="modalPrint = false" class="text-white" />
        </template>

        <template #footer>
          ----
          <!--
          <CButton @click="modalPrint = false" color="danger">Discard</CButton>

          -->
        </template>
      </CModal>
    </form>

    <!-------------------MODAL ALERT------------------------>
    <CModal :show.sync="modalAlert" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
      color="danger">
      {{ msgAlert }}
      <template #header>
        <h6 class="modal-title">
          <h3 class="text-3xl font-semibold">คำเตือน</h3>
        </h6>
        <CButtonClose @click="modalAlert = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="modalAlert = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltPrefix from "../components/SltPrefix.vue";
import SltStudent from "../components/SltStudent.vue";
import SltPosition from "../components/SltPosition.vue";
import SltAcademicStanding from "../components/SltAcademicStanding.vue";
import SltDepartment from "../components/SltDepartment.vue";
import SltDegree from "../components/SltDegree.vue";
import ChkPeriod from "../components/ChkPeriod.vue";
import UploadImages from "vue-upload-drop-images";
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltPrefix,
    SltStudent,
    SltPosition,
    SltAcademicStanding,
    SltDepartment,
    SltDegree,
    ChkPeriod,
    UploadImages, CChartBar
  },
  data() {
    return {
      columns: [
        {
          label: "#",
          field: "user_id",
          type: "number",
        },
        {
          label: "ชื่อ",
          field: "user_fname",
          type: "text",
        },

        {
          label: "นามสกุล",
          field: "user_lname",
          type: "text",
        },
        {
          label: "ตำแหน่ง",
          field: "position_name",
          type: "text",
        },
        {
          label: "วิทยะฐานะ",
          field: "as_name",
          type: "text",
        },
        {
          label: "กลุ่มสาระฯ/ ฝ่าย",
          field: "department_name",
        },
        {
          label: "วุฒิการศึกษา",
          field: "degree_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: ['ปริญญาตรี', 'ปริญญาโท', 'ปริญญาเอก'], // dropdown (with selected values) instead of text input

          },
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: [],
      rowsExcel: [],
      userData: this.$cookies.get("user"),
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      modalUploadPic: false,
      modalPrint: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
      handyAttachments: [],
      teachingLogInfo: null,
      selectedUser: null,
      positionLabel: [
        "ครู",
        "ครูผู้ช่วย",
        "รองผู้อำนวยการ",
        "ผู้อำนวยการ",
        "ไม่ระบุ",
      ],
      departmentLabel: [
        "ศิลปะฯ",
        "ภาษาต่างประเทศ",
        "คณิตศาสตร์",
        "การงานอาชีพ",
        "สุขศึกษาฯ",
        "สังคมฯ",
        "วิทยาศาสตร์ฯ",
        "ภาษาไทย",
        "วิชาการ",
        "อำนวยฯ",
        "บุคคลฯ",
        "ทั่วไปฯ",
        "ไม่ระบุ",
      ],
      acadStand: ['ครูชำนาญการ',
        'ครูชำนาญการพิเศษ',
        'ครูเชี่ยวชาญ',
        'รองผู้อำนวยการชำนาญการ',
        'รองผู้อำนวยการชำนาญการพิเศษ',
        'รองผู้อำนวนการเชี่ยวชาญ',
        'ผู้อำนวยการชำนาญการ',
        'ผู้อำนวยการชำนาญการพิเศษ',
        'ผู้อำนวยการเชี่ยวชาญ',
        'ไม่ระบุ'],
      degreeLabel: [
        'ปริญญาตรี', 'ปริญญาโท', 'ปริญญาเอก', 'ไม่ระบุ'
      ],
      chartData: {},
      selectedFiles: null
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH TEACHER INFO
    let formData = new FormData();
    formData.append("txtAction", "getGov");
    formData.append("qTeacher", "all");
    formData.append("schoolId", this.userData.user_address);
    this.axios
      .post(this.$hostUrl + "php_action/userAPI.php", formData)
      .then((response) => {
        console.log(response.data);
        this.$store.commit("setTeacherList", response.data.mainData);
        this.rowsExcel = response.data.excelData;
        this.chartData = response.data.info;
      });
  },
  methods: {
    openModal(appId) {
      this.modalCreate = true;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
          "php_action/appointmentAPI.php?txtAction=getSingleAppointment&appId=" +
          appId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedAppointment = response.data.mainData;
        });
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => { });
    },
    createUser(event) {
      //SEND DATA

      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createTeacher");
      formData.append("prefixId", 3);
      formData.append("schoolCode", this.userData.user_address);

      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.createState == "created") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        });
    },
    updateUser(event) {
      //console.log(this.$store.state.selectedDegree);
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "updateTeacher");
      formData.append("positionId", this.$store.state.selectedPosition.code);
      formData.append(
        "standingId",
        this.$store.state.selectedAcademicStanding.code
      );
      formData.append(
        "departmentId",
        this.$store.state.selectedDepartment.code
      );
      formData.append(
        "degreeId",
        this.$store.state.selectedDegree.code
      );

      if (this.selectedFiles) {
        formData.append("file", this.selectedFiles[0]);
      }

      formData.append("oldFile", this.selectedUser.user_pic);

      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.updateState == "updated") {
            //console.log(response.data);
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });

    },

    removeLog(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
            "php_action/teachingAPI.php?txtAction=delete&userId=" +
            this.userData.user_id +
            "&logId=" +
            value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          });

      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    openModalEdit(data) {
      this.modalEdit = true;
      this.selectedUser = this.$store.state.teacherList[data];
      console.log(this.selectedUser);
      this.$store.commit("setSelectedPosition", {
        code: this.selectedUser.position_id,
        label: this.selectedUser.position_name,
      });
      this.$store.commit("setSelectedAcademicStanding", {
        code: this.selectedUser.as_id,
        label: this.selectedUser.as_name,
      });
      this.$store.commit("setSelectedDepartment", {
        code: this.selectedUser.user_department,
        label: this.selectedUser.department_name,
      });
      this.$store.commit("setSelectedDegree", {
        code: this.selectedUser.degree_id,
        label: this.selectedUser.degree_name,
      });
      console.log(this.$store.state.selectedPosition);
    },
    uploadFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uppoaded");
            this.pictUrl1 = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uppoaded");
            this.pictUrl2 = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uppoaded");
            this.pictUrl3 = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uppoaded");
            this.pictUrl4 = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uppoaded");
            this.fileLink1 = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uppoaded");
            this.fileLink2 = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uppoaded");
            this.fileLink3 = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uppoaded");
            this.fileLink4 = this.$hostFileUrl + response.data.message.file8Url;
          }
        })
        .finally(() => { });
    },
    editFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uploaded");
            this.pic1New = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uploaded");
            this.pic2New = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uploaded");
            this.pic3New = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uploaded");
            this.pic4New = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uploaded");
            this.file1New = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uploaded");
            this.file2New = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uploaded");
            this.file3New = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uploaded");
            this.file4New = this.$hostFileUrl + response.data.message.file8Url;
          }
        });
    },
    handleImages(files) {
      this.selectedFiles = files;
      //console.log(this.selectedFiles[0]);
    },
  },
};
</script>
