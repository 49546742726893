<template>
  <div class="relative w-full mb-3">
    <div v-if="departmentInfo">
      เลือกกลุ่มสาระฯ/ ฝ่าย ที่สังกัด:
      <v-select
        :options="departmentInfo"
        v-model="$store.state.selectedDepartment"
        @input="mutateSelectedDepartment"
      >
        <template #search="{ attributes, events }">
          <input
            class="from-control ui-input-text"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </v-select>
    </div>
    <div v-else>loading...</div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      departmentInfo: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
    };
  },
  mounted() {
    //SET USER DATA
    this.userData = this.$cookies.get("user");

    //FETCH DEPARTMENT
    let formData = new FormData();
    formData.append("txtAction", "getAllDepartment");
    this.axios
      .post(this.$hostUrl + "php_action/departmentAPI.php", formData)
      .then((response) => {
        this.departmentInfo = response.data.mainData;
      });

  },
  methods: {
    mutateSelectedDepartment(value) {
      this.$store.commit("setSelectedDepartment", value);
    },
    mutateSelectedDistrict(value) {
      this.$store.commit("setSelectedDistrict", value);
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
