<template>
  <div class="relative w-full mb-3">
    <div v-if="standingInfo">
      <label
        class="block uppercase text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-password"
      >
        วิทยะฐานะ:
      </label>
      <v-select
        :options="standingInfo"
        v-model="$store.state.selectedAcademicStanding"
        @input="mutateSelectedAcademicStanding"
      >
        <template #search="{ attributes, events }">
          <input
            class="from-control ui-input-text"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </v-select>
    </div>
    <div v-else>loading...</div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      standingInfo: null,
    };
  },
  mounted() {
    //FETCH ASSET
    let formData = new FormData();
    formData.append("txtAction", "getAllAcademicStanding");
    this.axios
      .post(this.$hostUrl + "php_action/academicStandingAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.standingInfo = response.data.mainData;
      });
  },
  methods: {
    mutateSelectedAcademicStanding(value) {
      this.$store.commit("setSelectedAcademicStanding", value);
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
