<template>
  <div class="relative w-full mb-3" v-if="positionInfo">
    <label
      class="block uppercase text-gray-700 text-xs font-bold mb-2"
      htmlFor="grid-password"
    >
      ตำแหน่ง:
    </label>

    <v-select
      :options="positionInfo"
      v-model="$store.state.selectedPosition"
      @input="mutateSelectedPosition"
    >
      <template #search="{ attributes, events }">
        <input
          class="from-control ui-input-text"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      darkModal: false,
      positionInfo: null,
    };
  },
  mounted() {
    //FETCH POSITION DATA
    let formData = new FormData();
    formData.append("txtAction", "getAllPosition");

    this.axios
      .post(this.$hostUrl + "php_action/positionAPI.php", formData)
      .then((response) => {
        //this.$store.commit("setPrefixDropdown", response.data.mainData);
        this.positionInfo = response.data.dropdown;
      });
  },
  methods: {
    mutateSelectedPosition(value) {
      this.$store.commit("setSelectedPosition", value);
    },
    sendData() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append("selectedSubject", this.selectedSubject.code);
      formData.append("txtReason", this.selectedCiteria.code);
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == "") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 10%;
}
</style>
